import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import establishment from './establishmentSlice'

const reducer = combineReducers({
    session,
    establishment,
})

export default reducer
