import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'article/session',
    initialState: {
        token: '',
        establishmentSignedIn: false,
    },
    reducers: {
        onEstablishmentSignInSuccess: (state, action) => {
            state.establishmentSignedIn = true
            state.token = action.payload
        },
        onEstablishmentSignOutSuccess: (state) => {
            state.establishmentSignedIn = false
            state.token = ''
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
    },
})

export const { onEstablishmentSignInSuccess, onEstablishmentSignOutSuccess, setToken } = sessionSlice.actions

export default sessionSlice.reducer
