import React, {memo, useMemo, lazy, Suspense, useEffect} from 'react'
import { Loading } from 'components/shared'
import { useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useEstablishment from 'utils/hooks/useEstablishment'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import {useNetworkState} from "react-use";
import {Notification, toast} from "../ui";
import {Trans} from "react-i18next";
import {HiOutlineWifi} from "react-icons/hi";
import {RiSignalWifiOffLine} from "react-icons/ri";
import {IoWifiSharp} from "react-icons/io5";

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const layoutType = useSelector((state) => state.theme.layout.type)

    const { authenticated } = useAuth()
    const { authenticated: establishmentAuthenticated } = useEstablishment()

    useDirection()

    useLocale()

    const AppLayout = useMemo(() => {
        if (authenticated ) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated,])


    /*const {online} = useNetworkState()

    useEffect(() => {
        let dataNetwork = {}
        if (online){
            dataNetwork = {
                type: 'success',
                title: "layout.network.online",
                customIcon: <IoWifiSharp color={"green"} size={20} />
            }
        } else {
            dataNetwork = {
                type: 'danger',
                title: "layout.network.offline",
                customIcon: <RiSignalWifiOffLine color={"red"} size={20} />
            }
        }
        openNotification(dataNetwork)
        console.log(online, dataNetwork)
    }, [online]);*/


    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default memo(Layout)


const openNotification = (data) => {
    toast.push(
        <Notification
            title={<Trans i18nKey={data.title} />}
            type={data.type ? data.type : "success"}
            customIcon={data?.customIcon}
        >
            {data.message && <Trans i18nKey={data.message} />}
        </Notification>
    )
}
