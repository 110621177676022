import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {getInitArticles} from "../../../../utils/articles";

const cartSlice = createSlice({
    name: 'salesOrderList/cart',
    initialState: {
        list: [],
        loading: true
    },
    reducers: {
        cartAddByScan: (cart, action) => {
            let exist = false
            cart.list.forEach((i) => {
                if (i.article.shortcode === action.payload.article.shortcode) {
                    i.quantity++
                    exist = true
                }
            })
            if (!exist) {
                action.payload.quantity = 1
                cart.list.push(action.payload)
            }
            cart.loading = false
        },
        cartAddByChoose: (cart, action) => {
            let exist = false
            cart.list.forEach((i) => {
                if (i.article !== null && i.article.id === action.payload.article.id) {
                    i.quantity++
                    exist = true
                }
            })
            if (!exist) {
                action.payload.quantity = 1
                cart.list.push(action.payload)
            }
            cart.loading = false
        },
        cartChangeArticle: (cart, action) => {
            cart.list[action.payload.index].article = action.payload.article
            cart.loading = false
        },
        cartChangeQuantity: (cart, action) => {
            cart.list[action.payload.index].quantity = action.payload.quantity
            cart.loading = false
        },
        cartReset: (cart, action) => {
            cart.list = []
            cart.loading = false
        },
        cartRemoveItem: (cart, action) => {
            cart.list.splice(action.payload.index, 1)
            cart.loading = false
        },
    }
})

export default cartSlice.reducer;

export const {
    cartAddByScan,
    cartAddByChoose,
    cartRemoveItem,
    cartReset,
    cartChangeArticle,
    cartChangeQuantity

} = cartSlice.actions

export const addByScan = (barcode, dispatch) => async () => {
    let articles = getInitArticles()
    console.log(barcode)

    let article = articles['hydra:member'].find(a => parseInt(a.shortcode) === parseInt(barcode))
    if (article) dispatch({type: cartAddByScan.type, payload: {article}})
}

export const addByChoose = (cart) => async (dispatch) => {
    let articles = useSelector((state) => state.salesProductList.data.productList)
    let article = {}
    let find = false
    for (const a of articles) {
        if (parseInt(a.shortcode) === parseInt(cart.shortcode)) {
            article = a
            find = true
        }
    }
    if (!find) {
        /*dispatch(loadAlert({
          status: "error",
          title: "Article non trouvé",
          description: "L'article scanné avec la référence " + barcode + "n'a pas été trouvé",
          buttons: [{title: "Enregistrer le produit", color: "green", link: "/articles/new/" + barcode}]
        }))*/
        return;
    }
    article.quantity = cart.quantity
    return dispatch({type: cartAddByChoose.type, payload: article})
}

export const resetCart = () => (dispatch) => {
    return dispatch({type: cartReset().type})
}
