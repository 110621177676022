import { createSlice } from '@reduxjs/toolkit'
import {addArticle, getInitArticles, removeArticle} from "../../utils/articles";

export const initialState = {
    "id": 0,
    "name": "string",
    "slug": "string",
    "content": "string",
    "thumbnail": {
        "id": 0,
        "name": "string",
        "size": 0,
        "createdAt": "2023-11-02T04:27:26.688Z",
        "updatedAt": "2023-11-02T04:27:26.688Z"
    },
    "amount": 0,
    "isPromote": true,
    "promotePrice": 0,
    "currency": {
        "id": 0,
        "name": "string",
        "min": "string",
        "symbol": "string",
        "createdAt": "2023-11-02T04:27:26.688Z",
        "updatedAt": "2023-11-02T04:27:26.688Z"
    },
    "shortcode": "string",
    "quantity": 0,
    "type": "string",
    "isTaxable": true,
    "lowQuantity": 0,
    "isBookable": true,
    "reservationTime": 0,
    "reservationMargin": 0,
    "createdAt": "2023-11-02T04:27:26.688Z",
    "updatedAt": "2023-11-02T04:27:26.688Z",
    "isActive": true
}

export const articleSlice = createSlice({
    name: 'articles',
    initialState: {
        list: [],
        loading: true
    },
    reducers: {
        articlesRequested: (articles) => {
            articles.loading = true
        },
        articlesReceived: (articles, action) => {
            articles.list = action.payload
            articles.loading = false
        },
        articlesRequestedFailed: (articles) => {
            articles.loading = false
        },
        articlesAddItem: (articles, action) => {
            articles.list.push(action.payload)
        },
        articlesDeleteItem: (articles, action) => {
            articles.list = articles.list.filter((item) => item.id !== action.payload.id)
        },
    }
})

export const {articlesRequested, articlesReceived, articlesRequestedFailed, articlesAddItem, articlesDeleteItem} = articleSlice.actions

export default articleSlice.reducer


export const loadArticles = () => async (dispatch) => {
    let articles = await getInitArticles();
    return dispatch({type: articlesReceived.type, payload: articles})
}

export const doSyncArticles = () => async (dispatch) => {
    /*dispatch(loadAlert({
        title: "Synchronisation des articles en cours",
        loading: true,
        cancel: false
    }))
    let articles = await syncArticles();
    dispatch(dismissAlert())
    if (articles) {
        dispatch(loadAlert({
            status: "success",
            title: "Synchronisation reussie",
            description: "Les articles ont été synchronisé avec succès",
        }))
    }*/
    //return dispatch({type: articlesReceived.type, payload: articles})
}

export const newArticle = (a) => (dispatch) => {
    let article = addArticle(a)
    if (article === "EXIST") {
        /*dispatch(loadAlert({
            status: "error",
            title: "L'article existe déjà",
            description: "Un article a été retrouvé avec cette référence -" + a.shortcode,
        }))*/
    }
    else {
        /*dispatch(loadAlert({
            status: "success",
            title: "Création succès",
            description: "Votre article a été ajouté avec succès",
            buttons: [{title: "Voir", color: "green", link: "/articles/" + article.id}]
        }))*/
    }
    return dispatch({type: articlesAddItem.type, payload: article})
}

export const deleteArticle = (a) => (dispatch) => {
    let article = removeArticle(a)
    if (article) {
        /*dispatch(loadAlert({
            status: "success",
            title: "Article supprimé",
            description: "L'article " + a.name + " a été supprimé avec succès",
            buttons: [{title: "Continuer", color: "green", link: "/articles"}],
            cancel: false
        }))*/
    }
    return dispatch({type: articlesDeleteItem.type, payload: a})
}
