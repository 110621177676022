import React, {useEffect} from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import store, {persistor} from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import appConfig from 'configs/app.config'
import './locales'
//import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {ROUTE} from "./constants/route.constant";
import {useNetworkState} from "react-use";
import {Notification, toast} from "./components/ui";

//const environment = process.env.NODE_ENV

/*const client = new ApolloClient({
    uri: ROUTE,
    cache: new InMemoryCache(),
});*/


function App() {

    return (
        /*<ApolloProvider client={client}>*/
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter history={history}>
                        <Theme>
                            <Layout/>
                        </Theme>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        /*</ApolloProvider>*/
    )
}

export default App
