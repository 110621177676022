import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: '',
    thumbnail: '',
    name: '',
    slogan: '',
    email: '',
    phone: '',
    authorizations: []
}

export const establishmentSlice = createSlice({
    name: 'establishment/establishment',
    initialState,
    reducers: {
        setEstablishment: (_, action) => action.payload,
        setAuthorizations: (state, action) => void(state.authorizations = action.payload),
        establishmentLoggedOut: () => initialState,
    },
})

export const { setEstablishment, setAuthorizations } = establishmentSlice.actions

export default establishmentSlice.reducer
