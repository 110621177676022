export async function initArticles() {
  return JSON.parse(localStorage.getItem('articles')) ?? []
}

export function getInitArticles() {
  let articles = JSON.parse(localStorage.getItem('articles')) ?? []
  if (articles.length === 0) {
    articles = initArticles()
  }
  return articles
}

export function addArticle(article) {
  const currency = [{min: "USD", symbol: '$'}, {min: "CDF", symbol: "FC"}]
  article.currency = currency[article.currency - 1]
  article.priceNormal = parseInt(article.price)
  article.price = article.price * 100
  let articles = []
  if(localStorage.getItem('articles')) {
    articles = JSON.parse(localStorage.getItem('articles'))
    if (articles.find((item) => item.shortcode === article.shortcode)) return "EXIST";
  }
  articles.push(article)
  localStorage.setItem('articles', JSON.stringify(articles));
  return article
}

export function removeArticle(article) {
  let articles = JSON.parse(localStorage.getItem('articles'))
  if (article.shortcode) articles = articles.filter((item) => item.shortcode !== article.shortcode)
  else articles = articles.filter((item) => item.id !== article.id)
  localStorage.setItem('articles', JSON.stringify(articles));
  return true
}
