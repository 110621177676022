import {BARCODE_SCANNED, BARCODE_SCANNING} from 'react-usb-barcode-scanner';
import {addByScan} from "../../views/sales/OrderList/store/cartSlice";
//import {addByScan} from "../orders/cart";

export const barcodeScannerMiddleware = (store) => (next) => (action) => {

  if(action.type === BARCODE_SCANNED){
    const barcode = action.payload.data;
    store.dispatch(addByScan(barcode, store.dispatch))
  }

  if(action.type === BARCODE_SCANNING){
    console.log('Scanning')
  }
  return next(action);
};
