import { useSelector, useDispatch } from 'react-redux'
import { setEstablishment, setAuthorizations, initialState } from 'store/establishment/establishmentSlice'
import { apiEstablishmentSignIn, apiEstablishmentSignUp } from 'services/EstablishmentService'
import { onEstablishmentSignInSuccess, onEstablishmentSignOutSuccess } from 'store/establishment/sessionSlice'
import appConfig from 'configs/app.config'
import { useNavigate } from 'react-router-dom'

function useEstablishment() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { token, establishmentSignedIn } = useSelector((state) => state.establishment.session)

    const establishmentSignIn = async (values) => {
        try {
            const resp = await apiEstablishmentSignIn(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onEstablishmentSignInSuccess(token))
                if (resp.data.establishment) dispatch(setEstablishment(resp.data.establishment || {}))
                if (resp.data.authorizations) dispatch(setAuthorizations(resp.data.authorizations || []))
                navigate(appConfig.appEntryPath)
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const establishmentSignUp = async (values) => {
        try {
            const resp = await apiEstablishmentSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onEstablishmentSignInSuccess(token))
                if (resp.data.establishment) dispatch(setEstablishment(resp.data.establishment || {}))
                if (resp.data.authorizations) dispatch(setAuthorizations(resp.data.authorizations || []))
                navigate(appConfig.appEntryPath)
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onEstablishmentSignOutSuccess())
        dispatch(setEstablishment(initialState))
        navigate(appConfig.authenticatedEntryPath)
    }

    const establishmentSignOut = async () => {
        handleSignOut()
    }

    const establishment =  useSelector(
        (state) => state.establishment.establishment
    )

    return {
        authenticated: token && establishmentSignedIn,
        establishment,
        establishmentSignIn,
        establishmentSignUp,
        establishmentSignOut,
    }
}

export default useEstablishment
