const appConfig = {
    apiPrefix: '/api',
    appEntryPath: '/app/sales/dashboard',
    authenticatedEntryPath: '/self',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'fr',
    enableMock: true,
}

export default appConfig
