import ApiService from './ApiService'
import {API_ROUTE} from "../constants/route.constant";

export async function apiGetEstablishments() {
    return ApiService.fetchData({
        url: API_ROUTE + '/user/establishments',
        method: 'get'
    })
}

export async function apiEstablishmentSignIn(data) {
    return ApiService.fetchData({
        url: API_ROUTE + '/establishment/login/' + data,
        method: 'post',
    })
}

export async function apiEstablishmentJoin(data) {
    return ApiService.fetchData({
        url: API_ROUTE + '/establishment/join',
        method: 'post',
        data,
    })
}

export async function apiEstablishmentSignUp(data) {
    return ApiService.fetchData({
        url: API_ROUTE + '/establishment/register',
        method: 'post',
        data,
    })
}
