import axios from 'axios'
import appConfig from 'configs/app.config'
import {TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY, REQUEST_HEADER_APP_KEY} from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import {onEstablishmentSignOutSuccess} from "../store/establishment/sessionSlice";

const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

BaseService.defaults.headers.get['Accept'] = 'application/ld+json'
// BaseService.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
// BaseService.defaults.headers.get['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS'
// BaseService.defaults.headers.get['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization, App-Token'

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)
console.log(persistData)
        let accessToken = persistData.auth.session.token
        let appToken = persistData.establishment.session.token

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`
        }

        /*if (!appToken) {
            const { establishment } = store.getState()
            appToken = establishment.session.token
        }

        if (appToken) {
            config.headers[
                REQUEST_HEADER_APP_KEY
            ] = `${appToken}`
        }*/

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onEstablishmentSignOutSuccess())
            store.dispatch(onSignOutSuccess())
        }

        return Promise.reject(error)
    }
)

export default BaseService
