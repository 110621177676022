import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import article from './articleSlice'

const reducer = combineReducers({
    session,
    article,
})

export default reducer
